import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';

import './index.scss';

const AGIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page ag-page '} game="ag">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Artery Gear: Fusion wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Artery Gear: Fusion. Check our tier lists and
          reviews for characters available in Global, CN and JPN versions of
          game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="End of service" />
      <div className="banner eos">
        <div className="cta">
          <h4>Artery Gear - End of Service</h4>
          <p>
            Sadly, the dreaded day has come. On the 16th July 2024,{' '}
            <strong>
              Bilibili announced that Artery Gear will receive one last update
              and then the game will be put into maintenance mode
            </strong>{' '}
            - which means that within few months at most, the game will be
            closed down. You can read the note{' '}
            <a
              href="https://www.bilibili.com/opus/954322835327680513?spm_id_from=333.999.0.0"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
          <p>
            We will be reducing our support for the game and removing most of
            the content - we will only keep a snapshot of the last edition of
            our tier list. Thank you for the being with us!
          </p>
        </div>
      </div>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default AGIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Artery Gear | Prydwen Institute"
    description="Prydwen.gg is a wiki for Artery Gear: Fusion. Check our guides, tier lists and reviews for characters available in Global, CN and JPN versions of game."
  />
);
